<template>
  <bookings-layout :bookingsByDay="bookingsByDay" :loading="loading" :totalPages="totalPages" :currentPage="currentPage" :activeTab="activeTab" @loadMore="getBookings">
    <template v-slot:heading>Conclude Bookings</template>
    <template v-slot:bookingNavItems>
      <div class="mb__nav-item" :class="{ '--active': activeTab == 'awaiting' }" @click="changeTab('awaiting')">
        <i class="light-icon-lock-open"></i>
        Awaiting
      </div>
      <div class="mb__nav-item" :class="{ '--active': activeTab == 'checkin' }" @click="changeTab('checkin')">
        <i class="light-icon-file-check"></i>
        Check-in
      </div>
      <div class="mb__nav-item" :class="{ '--active': activeTab == 'inreview' }" @click="changeTab('inreview')">
        <i class="light-icon-file-invoice"></i>
        In-review
      </div>
      <div class="mb__nav-item" :class="{ '--active': activeTab == 'concluded' }" @click="changeTab('concluded')">
        <i class="light-icon-user-check"></i>
        Concluded
      </div>
    </template>
  </bookings-layout>
</template>

<script>
import VendorService from '@/services/vendor/VendorService';
import VendorBookingsService from '@/services/bookings/VendorBookingsService';
import vendorMixin from '@/mixins/vendorMixin';
import BookingsLayout from '@/components/manage-bookings/BookingsLayout.vue';

export default {
  mixins: [vendorMixin],
  components: { BookingsLayout },
  data() {
    return {
      vendor: null,
      bookings: [],
      loading: false,
      activeTab: 'awaiting',
    };
  },
  computed: {
    vendorId() {
      return this.$route.params.vendorId;
    },
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  async mounted() {
    window.addEventListener('scroll', this.handleScroll);
    if (this.vendorId && (!this.vendor || this.vendor._id !== this.vendorId)) {
      this.vendor = await VendorService.getVendor(this.vendorId);
      if (this.vendor && this.vendor._id) {
        window.localStorage.setItem('prebookVendor', JSON.stringify(this.vendor));
      }
    }
    this.activeTab = this.$route.query.list || 'awaiting';
    this.loading = true;
    console.log(this.vendor._id, 'vendor');
    this.bookings = await VendorBookingsService.getVendorBookingsByStatus(this.vendor._id, this.activeTab);
    console.log(this.bookings);
    this.groupBookingsByDay();
    this.loading = false;
  },
  methods: {
    async changeTab(list) {
      this.loading = true;
      this.bookings = null;
      this.bookingsByDay = null;
      this.activeTab = list;
      console.log(list);
      this.$router.push({
        name: 'ConcludeBookings',
        query: { list: this.activeTab },
      });
      this.bookings = await VendorBookingsService.getVendorBookingsByStatus(this.vendor._id, this.activeTab);
      this.groupBookingsByDay();
      this.loading = false;
    },
    groupBookingsByDay() {
      this.bookingsByDay = this.bookings.reduce((result, booking) => {
        const date = this.formatDate(booking.activity_date);
        if (!result[date]) {
          result[date] = [];
        }
        result[date].push(booking);
        return result;
      }, {});
    },
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

.mb__nav {
  background-color: #fff;
  box-sizing: border-box;
  margin: 0 -12px;
  margin-bottom: 14px;
  padding: 0 8px;

  &.--fixed {
    position: sticky;
    width: calc(100% + 24px);
    margin: 0 -12px;
    top: 50px;
    left: 0;
  }
  .mb__nav-inner {
    display: flex;
    align-items: center;
    gap: 0px;

    .mb__nav-item {
      padding: 12px 8px;
      font-size: 12px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      gap: 6px;
      box-sizing: border-box;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      &.--active {
        border-bottom: 2px solid $theme-color;
      }
    }
  }
}
</style>
