<template>
  <div class="shimmer" :style="{ height: height }"></div>
</template>

<script>
export default {
  props: ['height'],
};
</script>

<style lang="scss" scoped>
@keyframes shimmer {
  0% {
    background-color: #fff;
  }
  50% {
    background: #fbfbfb;
  }
  100% {
    background-color: #fff;
  }
}
.shimmer {
  width: 100%;
  box-shadow: 0 0 1px #00000025;
  border-radius: 6px;
  animation: shimmer 1s infinite;
}
</style>
