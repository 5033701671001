<template>
  <div class="get-started-page">
    <div class="page__inner">
      <div class="page__bg">
        <img class="page__bg-img" src="/images/rishikesh-painting.jpg" alt="" />
      </div>

      <div class="page__body">
        <div class="page__logo">
          <img src="@/assets/images/logo/logo.svg" alt="" class="page__logo-img" />
          <div class="page__logo__name">RISHIKESH.APP</div>
        </div>
        <div class="body-cta">
          <div class="body-cta-btn" @click="$router.push({ name: 'LoginView' })">Login</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

.get-started-page {
  .page__inner {
    .page__bg {
      width: 100%;
      height: 100vh;
      overflow: hidden;
      z-index: -1;

      .page__bg-img {
        width: 100%;
        height: 100vh;
        object-fit: cover;
        object-position: center;
      }
    }

    .page__body {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100vh;

      .page__logo {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        padding: 80px 0;
        .page__logo-img {
          height: 48px;
        }
      }
      .page__logo__name {
        font-size: 32px;
        color: #008080;
        font-weight: 500;
      }
      .body-cta {
        position: absolute;
        width: 100%;
        bottom: 80px;
        .body-cta-btn {
          width: 240px;
          margin: auto;
          background-color: $theme-color;
          padding: 12px 4px;

          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 40px;
          color: #fff;
          cursor: pointer;
          text-transform: uppercase;
          transition: all 0.3s ease-in-out;
          &:hover {
            background-color: #016969;
          }
          &:active {
            background-color: #025555;
          }
        }
      }
    }
  }
}
@media (min-width: 600px) {
  .get-started-page {
    .page__inner {
      .page__body {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }
  }
}
</style>
