const devConfig = {
  MANAGE_API_BASE_URL: 'http://localhost:4001/api',
};

const betaConfig = {
  MANAGE_API_BASE_URL: 'https://manage.api.beta.rishikesh.app/api',
};

const previewConfig = {
  MANAGE_API_BASE_URL: process.env.VUE_APP_MANAGE_API_BASE_URL || 'https://api-merchant-prebook-git-dev-digiikesh.vercel.app/api',
};

const prodConfig = {
  MANAGE_API_BASE_URL: 'https://manage.api.rishikesh.app/api',
};

const effectiveConfig = () => {
  switch (process.env.VUE_APP_MODE) {
    case 'development':
      return devConfig;
    case 'preview':
      return previewConfig;
    case 'beta':
      return betaConfig;
    case 'production':
      return prodConfig;
    default:
      return devConfig;
  }
};

export default effectiveConfig();
