<template>
  <!-- <navbar-section :vendor="vendor"></navbar-section> -->
  <!-- <page-section> -->
  <div class="manage-bookings-page">
    <div class="mb__header-bar">
      <h3 class="header-bar-title">
        <img src="@/assets/icons/manage-bookings.svg" alt="" class="header-bar-title-icon" />
        <slot name="heading">Manage Bookings</slot>
      </h3>
      <!-- <div class="header-bar-filter">
            <img
              src="@/assets/icons/filter.svg"
              alt=""
              class="header-bar-filter-icon"
            />
          </div> -->
    </div>

    <div class="mb__nav" :class="{ '--fixed': scrollDown == true && previousScrollPosition > 100 }">
      <div class="mb__nav-inner">
        <slot name="bookingNavItems"></slot>
      </div>
    </div>

    <div class="mb__bookings-days" v-for="(bookings, date) in bookingsByDay" :key="date">
      <h5 class="mb__bookings-days-header">{{ convertDate(date) }}</h5>
      <div class="mb__bookings" v-if="bookings && bookings.length">
        <div class="mb__bookings-list" v-for="(booking, index) in bookings" :key="index">
          <booking-history-card
            :forPage="activeTab"
            :booking="booking"
            @click="
              $router.push({
                name: 'CustomerDetailsPage',
                params: { bookingId: booking.booking_id, vendorId: vendor._id },
              })
            "
          ></booking-history-card>
        </div>
      </div>
    </div>
    <div class="load-more" v-if="!loading && totalPages && currentPage < totalPages" @click="$emit('loadMore')">{{ loading ? 'loading...' : 'LOAD MORE' }}</div>

    <div class="mb__bookings">
      <div class="shimmers-wrap" v-if="loading">
        <list-shimmer :height="'70px'"></list-shimmer>
        <list-shimmer :height="'70px'"></list-shimmer>
        <list-shimmer :height="'70px'"></list-shimmer>
      </div>
      <div class="mb__bookings-empty" v-if="!loading && bookingsByDay?.length">No bookings !</div>
    </div>
  </div>
  <!-- </page-section> -->
</template>

<script>
import vendorMixin from '@/mixins/vendorMixin';
import BookingHistoryCard from '@/components/manage-bookings/BookingHistoryCard.vue';
import ListShimmer from '@/components/common/ListShimmer.vue';

export default {
  mixins: [vendorMixin],
  props: ['bookingsByDay', 'loading', 'totalPages', 'currentPage', 'activeTab'],
  components: { BookingHistoryCard, ListShimmer },
  data() {
    return {
      previousScrollPosition: 0,
      scrollDown: false,
    };
  },

  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  async mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.pageYOffset > this.previousScrollPosition) {
        this.scrollDown = false;
      } else {
        this.scrollDown = true;
      }

      this.previousScrollPosition = window.pageYOffset;
    },
    convertDate(d) {
      let date = new Date(d);
      const options = {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      };

      const formattedDate = date.toLocaleDateString('en-US', options);
      return formattedDate;
    },

    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

.shimmers-wrap {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.manage-bookings-page {
  margin-top: 50px;
  padding: 0 12px;
  .mb__header-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 0;
    border-radius: 4px;

    .header-bar-title {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      font-weight: 400;
    }
    .header-bar-filter {
      .header-bar-filter-icon {
        font-size: 12px;
      }
    }
  }

  .mb__nav {
    background-color: #fff;
    box-sizing: border-box;
    margin: 0 -12px;
    margin-bottom: 14px;
    padding: 0 8px;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }

    &.--fixed {
      position: sticky;
      width: calc(100% + 24px);
      margin: 0 -12px;
      top: 50px;
      left: 0;
    }
    .mb__nav-inner {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      gap: 0px;

      .mb__nav-item {
        padding: 12px 8px;
        font-size: 12px;
        display: flex;
        align-items: center;
        gap: 6px;
        box-sizing: border-box;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        &.--active {
          border-bottom: 2px solid $theme-color;
        }
      }
    }
  }

  .mb__bookings-days {
    margin-top: 14px;

    .mb__bookings-days-header {
      font-size: 12px;
      font-weight: 400;
    }
  }
  .mb__bookings {
    .mb__bookings-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px;
      box-shadow: 0 0 1px #00000025;
      background-color: #fff;
      border-radius: 4px;
      margin: 6px 0;
      .mb__bookings-item-left {
        display: flex;
        flex-direction: column;
        gap: 2px;
        .item-left-name {
          font-size: 14px;
        }
        .item-left-activity {
          font-size: 12px;
        }
        .item-left-slot {
          font-size: 9px;
        }
      }

      .mb__bookings-item-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 4px;

        .item-right-cta {
          padding: 4px 12px;
          border-radius: 4px;
          font-size: 12px;

          &:hover {
            transform: scale(1.05);
          }

          &.--filled {
            background-color: $theme-color;
            color: #fff;
          }
          &.--hollow {
            background-color: #f5f5f5;
          }
        }
      }
    }
  }
  .load-more {
    border: 1px solid $theme-color;
    padding: 6px 12px;
    border-radius: 4px;
    width: fit-content;
    margin: 24px auto;
  }
}
</style>
