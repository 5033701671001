import appConfig from '@/config/index.ts';
// import router from "@/router";
import axios from 'axios';

class VendorService {
  static async getVendor(vendorId) {
    try {
      const fetchUrl = `${appConfig.MANAGE_API_BASE_URL}/v1/account/${vendorId}/details`;
      let vendor = await axios.get(fetchUrl);
      return vendor.data;
    } catch (error) {
      console.log(error);
    }
  }
  static async getUserVendor(userId) {
    try {
      const fetchUrl = `${appConfig.MANAGE_API_BASE_URL}/v1/account/list?userId=${userId}`;
      let vendor = await axios.get(fetchUrl);
      return vendor.data;
    } catch (error) {
      console.log(error);
    }
  }
}

export default VendorService;
