export default {
  mounted() {
    const locVendor = localStorage.getItem('prebookVendor');
    if (locVendor) {
      const locVendorData = JSON.parse(locVendor);
      if (locVendorData && locVendorData._id) {
        console.log(locVendorData);
        this.vendor = locVendorData;
      }
    }
  },
  computed: {
    vendor: {
      get: function () {
        return this.$store.state.vendor.vendor;
      },
      set: function (newValue) {
        this.$store.dispatch('vendor/setVendor', newValue);
      },
    },
    userVendors: {
      get: function () {
        return this.$store.state.vendor.userVendors;
      },
      set: function (newValue) {
        this.$store.dispatch('vendor/setUserVendors', newValue);
      },
    },
  },
};
