<template>
  <div class="checkin-page">
    <div class="qr-wrap">
      <qrcode-stream class="qr-window" @decode="onDecode" @init="onInit"></qrcode-stream>
      <div class="qr-postioning-layer">
        <div class="qr-pos-inner">
          <div class="close-icon" @click="$router.push({ name: 'VendorDashboardView', params: { vendorId: vendorId } })">
            <i class="light-icon-x"></i>
          </div>
          <h4 class="qr-pos__heading">Scan E-ticket QR code</h4>
          <div class="qr-pos__scan-box">
            <div class="qr-pos__scan-box-corner --corner-1"></div>
            <div class="qr-pos__scan-box-corner --corner-2"></div>
            <div class="qr-pos__scan-box-corner --corner-3"></div>
            <div class="qr-pos__scan-box-corner --corner-4"></div>
          </div>
          <p class="qr-pos-note">Please position the QR within the scanner</p>
        </div>
      </div>
    </div>
  </div>
  <div class="checkin-manual-wrap">
    <div class="checkin-input-wrap">
      <input v-model="bookingId" type="text" class="checkin-input" placeholder="Enter booking ID or Mobile number" />
      <!-- <img src="@/assets/icons/booking.svg" alt="" class="checkin-input-icon" /> -->
      <i class="light-icon-x checkin-input-icon" @click="bookingId = ''"></i>
    </div>
    <div class="checkin-btn" :class="{ '--disabled': !bookingId }" @click="proceedBooking()">
      <p class="checkin-btn-text">Proceed</p>
      <i class="checkin-btn-icon light-icon-chevron-right"></i>
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue3-qrcode-reader';

export default {
  components: { QrcodeStream },

  data() {
    return {
      scanning: false,
      result: '',
      bookingId: '',
      error: '',
    };
  },
  computed: {
    vendorId() {
      return this.$route.params.vendorId;
    },
  },
  methods: {
    onDecode(content) {
      this.result = content;
      this.$router.push({
        name: 'CustomerDetailsPage',
        params: { bookingId: this.result },
        query: { detailsType: 'check-in' },
      });
    },
    proceedBooking() {
      this.$router.push({
        name: 'CustomerDetailsPage',
        params: { bookingId: this.bookingId },
        query: { detailsType: 'check-in' },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

.checkin-page {
  margin-top: 0;
  // height: 100vh;
  position: relative;

  .qr-wrap {
    position: relative;
    .qr-window {
      height: calc(100vh - 140px);
      width: 100%;
    }
    .qr-postioning-layer {
      z-index: 20;
      width: 100%;
      height: 70vh;
      background-color: transparent;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .qr-pos-inner {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        justify-content: center;

        .close-icon {
          position: absolute;
          right: 21px;
          top: 30px;
          font-size: 20px;
          font-weight: 400;
          cursor: pointer;
          text-shadow: 0 0 20px #292929;
          color: #ffffff;
        }

        .qr-pos__heading {
          color: #ffffff;
          font-weight: 500;
          font-size: 14px;
          text-shadow: 0 0 20px #292929;
        }

        .qr-pos__scan-box {
          width: 150px;
          height: 150px;
          position: relative;

          .qr-pos__scan-box-corner {
            position: absolute;
            width: 24px;
            height: 24px;
            border-color: $theme-color;
            border-style: solid;

            &.--corner-1 {
              left: 0;
              top: 0;
              border-width: 2px 0 0 2px;
            }
            &.--corner-2 {
              right: 0;
              top: 0;
              border-width: 2px 2px 0 0;
            }
            &.--corner-3 {
              right: 0;
              bottom: 0;
              border-width: 0 2px 2px 0;
            }
            &.--corner-4 {
              left: 0;
              bottom: 0;
              border-width: 0 0 2px 2px;
            }
          }
        }
        .qr-pos-note {
          font-size: 9px;
          color: #ffffff;
          text-shadow: 0 0 20px #292929;
        }
      }
    }
  }
}

.checkin-manual-wrap {
  // margin-top: 24px;
  background-color: #fff;
  border-radius: 12px 12px 0 0;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.07);
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 150px;
  padding: 36px 12px;
  .checkin-input-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #00000025;
    border-radius: 6px;
    overflow: hidden;
    padding: 0 14px;
    .checkin-input {
      outline: none;
      width: 100%;
      padding: 12px 0;
      box-sizing: border-box;
      font-size: 12px;
      border: none;
      &::placeholder {
        color: #00000044;
      }
    }
    .checkin-input-icon {
      width: 14px;
    }
  }
  .checkin-btn {
    margin: 16px auto 0;
    width: fit-content;
    color: #ffffff;
    background-color: $theme-color;
    padding: 8px 24px;
    text-align: center;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;

    &:hover {
      background-color: $theme-color-hover;
    }

    &.--disabled {
      pointer-events: none;
      background-color: $theme-color-hover;
      color: #eaeaea;
    }

    .checkin-btn-icon {
      font-size: 20px;
      margin-right: -12px;
    }
  }
}
@media (min-width: 600px) {
  .checkin-manual-wrap {
    .checkin-input-wrap {
      width: 450px;
      margin: 0 auto;
    }
  }
}
</style>
