import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './assets/scss/global.scss';
import 'light-icons/dist/light-icon.css';

// FIREBASE -------------------------
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfigDev = {
  apiKey: 'AIzaSyAXjSKHC5d9FIzs4_RsXfCF8EJWseE3nuQ',
  authDomain: 'preboook-fbac6.firebaseapp.com',
  projectId: 'preboook-fbac6',
  storageBucket: 'preboook-fbac6.appspot.com',
  messagingSenderId: '112978298669',
  appId: '1:112978298669:web:b357dfda486d1e325675a4',
};
const firebaseConfigProd = {
  apiKey: 'AIzaSyCu_fvsMznuAljWDiqrgmd4r9aakIo8VSI',
  authDomain: 'prebook-v.firebaseapp.com',
  projectId: 'prebook-v',
  storageBucket: 'prebook-v.appspot.com',
  messagingSenderId: '1036304645036',
  appId: '1:1036304645036:web:5885c2e212676d27861809',
  measurementId: 'G-8NMGJ6XNEQ',
};

// Initialize Firebase
initializeApp(process.env.VUE_APP_MODE === 'production' ? firebaseConfigProd : firebaseConfigDev);
// FIREBASE -------------------------------------

createApp(App).use(store).use(router).mount('#app');
