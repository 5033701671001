<template>
  <div class="app-body">
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.app-body {
  max-width: 500px;
  margin: auto;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.07);
  min-height: 100vh;
}
</style>
