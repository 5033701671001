import { createRouter, createWebHistory, RouteRecordRaw, NavigationGuardNext, RouteLocationNormalized, RouteLocationNormalizedLoaded } from 'vue-router';

import { getAuth, onAuthStateChanged } from 'firebase/auth';
import HomeView from '../views/HomeView.vue';
import OnboardingLayout from '../views/onboarding/OnboardingLayout.vue';
import GetStartedPage from '../views/onboarding/GetStartedPage.vue';
import AuthPage from '../views/onboarding/AuthPage.vue';
import LoginPage from '../views/onboarding/LoginPage.vue';
import SignupPage from '../views/onboarding/SignupPage.vue';
import DummyProfile from '../views/DummyProfile.vue';
import DashboardPage from '../views/vendor/DashboardPage.vue';
import ManageBookings from '../views/vendor/ManageBookings.vue';
import BookingRequests from '../views/vendor/BookingRequest.vue';
import ConcludeBookings from '../views/vendor/ConcludeBookings.vue';
import BookingHistoryPage from '../views/vendor/BookingHistoryPage.vue';
import CustomerDetailsPage from '../views/vendor/CustomerDetailsPage.vue';
import CheckInPage from '../views/vendor/CheckInPage.vue';
import VendorLayout from '../views/vendor/VendorLayout.vue';
import AppLayout from '@/layouts/AppLayout.vue';
import AccountsListView from '@/views/AccountsListView.vue';
import SettingsLayout from '@/views/user/settings/SettingsLayout.vue';
import AccountSettings from '@/views/user/settings/AccountSettings.vue';
import UserSettings from '@/views/user/settings/UserSettings.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'HomeView',
    redirect: {
      name: 'LoginView',
    },
  },
  {
    path: '/auth',
    component: OnboardingLayout,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: '/get-started',
        name: 'GetStartedView',
        component: GetStartedPage,
      },
      {
        path: '/login',
        name: 'LoginView',
        component: LoginPage,
      },
      {
        path: '/register',
        name: 'RegisterView',
        component: SignupPage,
      },
    ],
  },
  {
    // To add authGuard
    path: '/app',
    component: AppLayout,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: '/accounts',
        name: 'AccountsListView',
        component: AccountsListView,
      },
      {
        path: '/settings',
        name: 'SettingsLayout',
        component: SettingsLayout,
        children: [
          {
            path: 'account/:accountId',
            name: 'AccountSettings',
            component: AccountSettings,
          },
          {
            path: 'user',
            name: 'UserSettings',
            component: UserSettings,
          },
        ],
      },
      {
        path: '/account/:vendorId',
        component: VendorLayout,
        children: [
          {
            path: '',
            redirect: {
              name: 'VendorDashboardView',
            },
          },
          {
            path: 'dashboard',
            name: 'VendorDashboardView',
            component: DashboardPage,
          },
          {
            path: 'bookings/manage',
            name: 'ManageBookings',
            component: ManageBookings,
          },
          {
            path: 'bookings/requests',
            name: 'BookingRequests',
            component: BookingRequests,
          },
          {
            path: 'bookings/conclude',
            name: 'ConcludeBookings',
            component: ConcludeBookings,
          },
          {
            path: 'booking-details/:bookingId',
            name: 'CustomerDetailsPage',
            component: CustomerDetailsPage,
          },
          {
            path: 'check-in',
            name: 'GuestCheckInView',
            component: CheckInPage,
          },
        ],
      },
    ],
  },
  {
    // no longer in-use
    path: '/onboarding',
    name: 'OnboardingLayout',
    component: OnboardingLayout,
    children: [
      {
        path: 'get-started',
        name: 'GetStarted',
        component: GetStartedPage,
      },
      {
        path: 'auth',
        name: 'AuthPage',
        component: AuthPage,
      },
      // {
      //   path: 'login',
      //   name: 'LoginPage',
      //   component: LoginPage,
      // },
      {
        path: 'sign-up',
        name: 'SignupPage',
        component: SignupPage,
      },
      {
        path: 'dumy-profile',
        name: 'DummyProfile',
        component: DummyProfile,
      },
    ],
  },
  // {
  //   path: '/:vendorId',
  //   name: 'VendorLayout',
  //   component: VendorLayout,
  //   children: [
  //     {
  //       path: 'dashboard',
  //       name: 'DashboardPage',
  //       component: DashboardPage,
  //     },
  //     {
  //       path: 'manage-bookings',
  //       name: 'ManageBookings',
  //       component: ManageBookings,
  //     },
  //     {
  //       path: 'booking-requests',
  //       name: 'BookingRequests',
  //       component: BookingRequests,
  //     },
  //     {
  //       path: 'conclude-bookings',
  //       name: 'ConcludeBookings',
  //       component: ConcludeBookings,
  //     },
  //     {
  //       path: 'booking-history',
  //       name: 'BookingHistoryPage',
  //       component: BookingHistoryPage,
  //     },
  //     {
  //       path: 'booking-details/:bookingId',
  //       name: 'CustomerDetailsPage',
  //       component: CustomerDetailsPage,
  //     },
  //   ],
  // },
  {
    path: '/check-in',
    name: 'CheckInPage',
    component: CheckInPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded, next: NavigationGuardNext) => {
  if (to.matched.some(record => record.meta.authRequired)) {
    const auth = getAuth();
    try {
      await new Promise<void>((resolve, reject) => {
        const unsubscribe = onAuthStateChanged(auth, user => {
          unsubscribe(); // Unsubscribe to the listener after receiving the initial user state
          if (user) {
            // User is authenticated
            if (to.name !== 'LoginView' && to.name !== 'RegisterView' && to.name !== 'GetStartedView') {
              next();
            } else {
              next({ path: '/accounts' });
            }
          } else {
            // User is not authenticated
            if (to.name === 'LoginView' || to.name === 'RegisterView' || to.name == 'GetStartedView') {
              next();
            } else {
              next({ name: 'LoginView' });
            }
          }
          resolve();
        });
      });
    } catch (error) {
      console.error('Error checking authentication:', error);
      next({ path: '/error' }); // Redirect to an error page or handle the error appropriately
    }
  } else {
    next();
  }
});

export default router;
