<template>
  <div class="settings-page">
    <h3 class="header-bar">
      <img src="@/assets/icons/manage-bookings.svg" alt="" class="header-bar-title-icon" />
      Personal Details
    </h3>
    <div class="settings-card">
      <div class="settings-item">
        <div class="settings-dp"></div>
      </div>
      <div class="settings-item">
        <label for="" class="settings__input-label">Name</label>
        <div class="settings__input-wrap">
          <input v-model="userName" type="text" class="settings__input" placeholder="Enter your name" />
        </div>
      </div>
      <div class="settings-item">
        <label for="" class="settings__input-label">Phone no.</label>
        <div class="settings__input-wrap">
          <input v-model="userPhone" type="number" class="settings__input" placeholder="Enter your phone no." />
        </div>
      </div>
      <div class="settings-item">
        <label for="" class="settings__input-label">Role</label>
        <lv-dropdown v-model="userRole" :value="userRole" :options="['user', 'contributor', 'vendor_admin', 'exclusive_partner', 'super_admin']"></lv-dropdown>
      </div>

      <div class="save-btn-wrap">
        <div class="save-btn" @click="update()">Save</div>
      </div>
    </div>
  </div>
</template>

<script>
import LvDropdown from 'lightvue/dropdown';
import UserUpdateService from '@/services/user/UserUpdateServices';
import userMixin from '@/mixins/user/userMixin';
export default {
  mixins: [userMixin],
  components: {
    LvDropdown,
  },
  data() {
    return {
      userName: '',
      userPhone: '',
      userRole: '',
    };
  },
  mounted() {
    if (this.user) {
      this.userName = this.user.display_name;
      this.userPhone = this.user.phone_number;
      this.userRole = this.user.account_type;
    }
  },
  methods: {
    async update() {
      const data = {
        name: this.userName,
        phone: this.userPhone,
        role: this.userRole,
      };
      let user = await UserUpdateService.updateUser(this.user._id, data);
      this.user = user.updatedUser;
      window.localStorage.setItem('prebookUser', JSON.stringify(this.user));
      console.log(user, 'updated');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';

.settings-page {
  margin-top: 50px;
  padding: 20px 12px;

  .header-bar {
    display: flex;
    align-items: center;
    padding: 14px 0;
    gap: 8px;
    font-size: 14px;
    font-weight: 400;
  }

  .settings-card {
    background-color: #fff;
    box-shadow: 0 0 1px #00000025;
    padding: 12px;
    border-radius: 4px;

    .settings-dp {
      width: 100px;
      height: 100px;
      border-radius: 50px;
      background-color: #edf2f759;
    }

    .settings-item {
      margin-bottom: 14px;
    }
    .settings__input-label {
      font-size: 14px;
      font-weight: 500;
      color: #0d21316a;
    }
    .settings__input-wrap {
      margin-top: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      //   border: 1px solid #00000025;
      background-color: #edf2f759;
      border-radius: 6px;
      overflow: hidden;
      padding: 0 14px;
      .settings__input {
        outline: none;
        width: 100%;
        padding: 12px 0;
        box-sizing: border-box;
        background-color: #edf2f759;
        font-size: 12px;
        border: none;
        &::placeholder {
          color: #00000044;
        }
      }
      .settings__input-icon {
        width: 14px;
      }
    }
  }
  .save-btn-wrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .save-btn {
      width: fit-content;
      background-color: $theme-color;
      color: #fff;
      border-radius: 4px;
      padding: 8px 33px;
      cursor: pointer;

      &:hover {
        background-color: $theme-color-hover;
      }
    }
  }
}
</style>
