<template>
  <router-view />
</template>
<script>
import AuthService from '@/services/user/AuthService';
export default {
  mounted() {
    // AuthService.login()
  },
};
</script>
<style lang="scss">
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
</style>
