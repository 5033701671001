<template>
  <div>
    <navbar-section :vendor="vendor" v-if="$route.name !== 'GuestCheckInView'"></navbar-section>
    <router-view />
  </div>
</template>

<script>
import NavbarSection from '@/components/common/NavbarSection.vue';
import VendorService from '@/services/vendor/VendorService';
import vendorMixin from '@/mixins/vendorMixin';
export default {
  mixins: [vendorMixin],
  components: { NavbarSection },
  data() {
    return {
      vendor: null,
    };
  },
  computed: {
    vendorId() {
      return this.$route.params.vendorId;
    },
  },
  async mounted() {
    if (this.vendorId && (!this.vendor || this.vendor._id !== this.vendorId)) {
      this.vendor = await VendorService.getVendor(this.vendorId);
      if (this.vendor && this.vendor._id) {
        window.localStorage.setItem('prebookVendor', JSON.stringify(this.vendor));
      }
    }
  },
};
</script>
