<template>
  <h2>User Profile</h2>
  <h6>User Logged In</h6>
  <h5 v-if="user">Id: {{ user._id }}</h5>

  <button @click="logout()">Log out</button>
  <br />
  <button @click="logoutAll()">Log out All devices</button>
</template>

<script>
import userMixin from '@/mixins/user/userMixin';
import axios from 'axios';
import { getAuth, signOut } from 'firebase/auth';

export default {
  mixins: [userMixin],
  methods: {
    logout() {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          // Sign-out successful.
          localStorage.removeItem('prebookUser');
          this.user = null;
          this.$router.push({ name: 'LoginPage' });
        })
        .catch(error => {
          // An error happened.
        });
    },
    logoutAll() {
      let uid = this.user.firebase_uid;

      axios.post('http://localhost:8800/api/auth/revoke-token', { uid: uid }, (res, err) => {
        console.log(res);
      });
    },
  },
};
</script>

<style></style>
