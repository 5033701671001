import { createStore } from 'vuex'
import user from "./modules/user";
import vendor from "./modules/vendor";

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    vendor,
    user
  }
})
