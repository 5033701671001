<template>
  <bookings-layout :bookingsByDay="bookingsByDay" :loading="loading" :totalPages="totalPages" :currentPage="currentPage" :activeTab="activeTab" @loadMore="getBookings">
    <template v-slot:heading>Manage Bookings</template>
    <template v-slot:bookingNavItems>
      <div class="mb__nav-item" :class="{ '--active': activeTab == 'history' }" @click="changeTab('history')">
        <i class="light-icon-clock"></i>
        History <small v-if="historyCount">({{ historyCount }})</small>
      </div>
      <div class="mb__nav-item" :class="{ '--active': activeTab == 'today' }" @click="changeTab('today')">
        <i class="light-icon-calendar-event"></i>
        Today <small v-if="todayCount">({{ todayCount }})</small>
      </div>
      <div class="mb__nav-item" :class="{ '--active': activeTab == 'upcoming' }" @click="changeTab('upcoming')">
        <i class="light-icon-calendar-plus"></i>
        Upcoming <small v-if="upcomingCount">({{ upcomingCount }})</small>
      </div>
    </template>
  </bookings-layout>
</template>

<script>
// import PageSection from '@/components/common/PageSection.vue';
import VendorService from '@/services/vendor/VendorService';
import VendorBookingsService from '@/services/bookings/VendorBookingsService';
import vendorMixin from '@/mixins/vendorMixin';
import BookingsLayout from '@/components/manage-bookings/BookingsLayout.vue';

export default {
  mixins: [vendorMixin],
  components: { BookingsLayout },
  data() {
    return {
      vendor: null,
      bookings: [],
      bookingsByDay: [],
      loading: false,
      activeTab: 'today',
      currentPage: 0,
      totalPages: 0,
      todayCount: null,
      historyCount: null,
      upcomingCount: null,
    };
  },
  computed: {
    vendorId() {
      return this.$route.params.vendorId;
    },
  },
  watch: {
    $route() {
      this.activeTab = this.$route.query.list;
    },
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  async mounted() {
    window.addEventListener('scroll', this.handleScroll);
    if (this.vendorId && (!this.vendor || this.vendor._id !== this.vendorId)) {
      this.vendor = await VendorService.getVendor(this.vendorId, this.currentPage);
      if (this.vendor && this.vendor._id) {
        window.localStorage.setItem('prebookVendor', JSON.stringify(this.vendor));
      }
    }
    if (this.$route.query.list) {
      this.activeTab = this.$route.query.list;
    }
    this.bookings = [];
    this.getBookings();
    this.getTabsCounts();
  },
  methods: {
    async getBookings() {
      this.loading = true;
      this.currentPage++;
      // this.activeTab = this.$route.query.list || 'today';
      let bookings = await VendorBookingsService.getVendorBookings(this.vendor._id, this.activeTab, this.currentPage);
      this.bookings.push(...bookings.bookings);
      this.totalPages = bookings.totalPages;
      this.groupBookingsByDay();
      this.loading = false;
    },
    async getTabsCounts() {
      const result = await VendorBookingsService.getVendorBookingsCount(this.vendor._id);
      this.todayCount = result.today;
      this.upcomingCount = result.upcoming;
      this.historyCount = result.history;
    },
    changeTab(list) {
      this.activeTab = list;
      this.bookings = [];
      this.bookingsByDay = [];
      this.$router.push({
        name: 'ManageBookings',
        query: { list: this.activeTab },
      });
      this.currentPage = 0;
      this.getBookings();
    },

    groupBookingsByDay() {
      if (this.bookings && this.bookings.length) {
        this.bookingsByDay = this.bookings?.reduce((result, booking) => {
          const date = this.formatDate(booking.activity_date);
          if (!result[date]) {
            result[date] = [];
          }
          result[date].push(booking);
          return result;
        }, {});
      }
    },
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

.mb__nav {
  background-color: #fff;
  box-sizing: border-box;
  margin: 0 -12px;
  margin-bottom: 14px;
  padding: 0 8px;

  &.--fixed {
    position: sticky;
    width: calc(100% + 24px);
    margin: 0 -12px;
    top: 50px;
    left: 0;
  }
  .mb__nav-inner {
    display: flex;
    align-items: center;
    gap: 0px;

    .mb__nav-item {
      padding: 12px 8px;
      font-size: 12px;
      display: flex;
      align-items: center;
      gap: 6px;
      box-sizing: border-box;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      &.--active {
        border-bottom: 2px solid $theme-color;
      }
    }
  }
}
</style>
