<template>
  <div class="dashboard-page">
    <div class="dashboard__overview-card">
      <div class="overview__heading">
        <h3 class="overview__heading-title">Booking Insights</h3>
        <!-- <div class="overview__heading-filter">
          <p>This week</p>
        </div> -->
      </div>

      <div class="overview__loader-box" v-if="!bookingOverview">
        <loader type="ball-beat" color="#38b2ac" />
      </div>
      <div class="overview__data-sections" v-else>
        <div class="overview__data">
          <h2 class="overview__data-value">
            {{ bookingOverview?.totalBookings || 0 }}
          </h2>
          <p class="overview__data-label">Total Bookings</p>
        </div>
        <div class="overview__data">
          <h2 class="overview__data-value">
            {{ bookingOverview?.totalBookings - bookingOverview?.upcomingBookings || 0 }}
            <!-- {{ bookingOverview?.completedBookings || 0 }} -->
          </h2>
          <p class="overview__data-label">Completed</p>
        </div>
        <div class="overview__data">
          <h2 class="overview__data-value">
            {{ bookingOverview?.upcomingBookings || 0 }}
          </h2>
          <p class="overview__data-label">Upcoming</p>
        </div>
      </div>
      <StatsGraphs v-if="bookingOverview && bookingOverview.timeSeries" :series="bookingOverview.timeSeries" />
    </div>

    <div class="dashboard__options">
      <div class="options__row">
        <div
          class="options__row-item"
          @click="
            $router.push({
              name: 'BookingRequests',
              params: { vendorId: vendor._id },
            })
          "
        >
          <div class="options__row-item-icon-wrap">
            <img src="@/assets/icons/dash-icon0.svg" alt="" class="options__row-item-icon" />
            <div class="active-request-dot"></div>
          </div>
          <p class="options__row-item-label">Booking Request</p>
        </div>
        <div
          class="options__row-item"
          @click="
            $router.push({
              name: 'ManageBookings',
              params: { vendorId: vendor._id },
            })
          "
        >
          <div class="options__row-item-icon-wrap">
            <img src="@/assets/icons/dash-icon1.svg" alt="" class="options__row-item-icon" />
          </div>
          <p class="options__row-item-label">Manage Bookings</p>
        </div>
        <div
          class="options__row-item"
          @click="
            $router.push({
              name: 'ConcludeBookings',
              params: { vendorId: vendor._id },
            })
          "
        >
          <div class="options__row-item-icon-wrap">
            <img src="@/assets/icons/dash-icon3.svg" alt="" class="options__row-item-icon" />
          </div>
          <p class="options__row-item-label">Conclude Bookings</p>
        </div>
      </div>
      <div class="options__row">
        <div class="options__row-item" @click="navigateTo('ManageBookings', 'upcoming')">
          <div class="options__row-item-icon-wrap">
            <img src="@/assets/icons/dash-icon2.svg" alt="" class="options__row-item-icon" />
          </div>
          <p class="options__row-item-label">Upcoming Bookings</p>
        </div>
        <div class="options__row-item" @click="navigateTo('ManageBookings', 'history')">
          <div class="options__row-item-icon-wrap">
            <img src="@/assets/icons/dash-icon4.svg" alt="" class="options__row-item-icon" />
          </div>
          <p class="options__row-item-label">Booking History</p>
        </div>
        <div class="options__row-item" @click="openSupport()">
          <div class="options__row-item-icon-wrap">
            <img src="@/assets/icons/dash-icon5.svg" alt="" class="options__row-item-icon" />
          </div>
          <p class="options__row-item-label">Support</p>
        </div>
        <!-- <div class="options__row-item">
            <img
              src="@/assets/icons/dash-icon6.svg"
              alt=""
              class="options__row-item-icon"
            />
            <p class="options__row-item-label">Settings</p>
          </div> -->
      </div>
    </div>

    <div class="dashboard__checkin">
      <div class="checkin__btn" @click="$router.push({ name: 'GuestCheckInView' })">
        <img src="@/assets/icons/scanner.svg" alt="" class="checkin__btn-icon" />
        <h4 class="checkin__btn-text">Check-In</h4>
      </div>
    </div>
  </div>
</template>

<script>
import VendorService from '@/services/vendor/VendorService';
import VendorBookingsService from '@/services/bookings/VendorBookingsService';
import vendorMixin from '@/mixins/vendorMixin';
import Loader from 'lightvue/loaders';
import StatsGraphs from '@/components/vendor-dashboard/StatsGraph.vue';
import { getWhatsappMessageLink } from '@/utils/internalMessage.js';
export default {
  mixins: [vendorMixin],
  components: { Loader, StatsGraphs },
  data() {
    return {
      vendor: null,
      bookingOverview: null,
      // bookings: [],
    };
  },
  computed: {
    vendorId() {
      return this.$route.params.vendorId;
    },
  },
  async mounted() {
    if (this.vendorId && (!this.vendor || this.vendor._id !== this.vendorId)) {
      this.vendor = await VendorService.getVendor(this.vendorId);
      if (this.vendor && this.vendor._id) {
        window.localStorage.setItem('prebookVendor', JSON.stringify(this.vendor));
      }
    }
    this.getStats();
    // this.bookings = await VendorBookingsService.getVendorBookings(this.vendor._id);
  },
  methods: {
    openSupport() {
      window.open(
        getWhatsappMessageLink(`Hi, I'm from ${this.vendor?.vendor_name}.

I need your little help`),
        '_blank'
      );
    },
    async getStats() {
      this.bookingOverview = await VendorBookingsService.getVendorBookingsOverview(this.vendor._id);
    },
    navigateTo(name, query) {
      this.showSidbar = false;
      this.$router.push({
        name: name,
        params: { vendorId: this.vendor?._id },
        query: { list: query },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

.dashboard-page {
  margin-top: 50px;
  padding: 20px 12px;
  .dashboard__overview-card {
    background-color: #fff;
    box-shadow: 0 0 1px #00000025;
    padding: 12px;
    border-radius: 4px;
    position: relative;

    .overview__loader-box {
      width: 100%;
      height: 200px;
      // position: absolute;
      // left: 0;
      // bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      backdrop-filter: blur(1px);
    }

    .overview__heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px dashed #0d213111;
      padding-bottom: 6px;
      margin-bottom: 6px 0;

      .overview__heading-title {
        font-size: 16px;
        font-weight: 500;
      }

      .overview__heading-filter {
        background-color: #fcfcfc;
        border-radius: 4px;
        padding: 6px 12px;
        font-size: 12px;
      }
    }

    .overview__data-sections {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      margin-top: 12px;

      .overview__data {
        width: 33%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 6px;

        .overview__data-value {
          font-size: 16px;
          font-weight: 600;
        }
        .overview__data-label {
          font-size: 9px;
        }
      }
    }
  }

  .dashboard__options {
    margin-top: 12px;

    .options__row {
      display: flex;
      justify-content: space-between;
      gap: 12px;
      margin-bottom: 12px;

      .options__row-item {
        flex: 1 1 0;
        height: 100px;
        border-radius: 4px;
        box-shadow: 0 0 1px #00000025;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;
        background-color: #fff;
        cursor: pointer;

        .options__row-item-icon-wrap {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #00808009;
          border-radius: 40px;
          position: relative;
          .options__row-item-icon {
            width: 20px;
          }
          .active-request-dot {
            position: absolute;
            right: -2px;
            top: 6px;
            height: 6px;
            width: 6px;
            border-radius: 20px;
            background-color: #ff8080;
          }
        }
        .options__row-item-label {
          font-size: 9px;
        }

        &:hover {
          box-shadow: 1px 1px 5px #00000025;
        }
      }
    }
  }

  .dashboard__checkin {
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 16px 0;

    .checkin__btn {
      background-color: $theme-color;
      padding: 12px 22.5px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      width: fit-content;
      margin: 0 auto;
      color: #fff;
      border-radius: 50px;
      cursor: pointer;
      &:hover {
        background-color: $theme-color-hover;
      }

      .checkin__btn-icon {
        width: 21px;
      }
      .checkin__btn-text {
        font-size: 14px;
      }
    }
  }
}

@media (min-width: 600px) {
  .dashboard-page {
    .dashboard__overview-card {
      .overview__heading {
        .overview__heading-title {
          font-size: 18px;
        }

        .overview__heading-filter {
          font-size: 14px;
        }
      }

      .overview__data-sections {
        padding: 16px 0;

        .overview__data {
          .overview__data-value {
            font-size: 18px;
          }
          .overview__data-label {
            font-size: 12px;
          }
        }
      }
    }

    .dashboard__options {
      .options__row {
        .options__row-item {
          .options__row-item-label {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
