import appConfig from '@/config/index.ts';
import axios from 'axios';

class VendorService {
  static async getVendorBookingsOverview(vendorId) {
    try {
      const fetchUrl = `${appConfig.MANAGE_API_BASE_URL}/v1/account/${vendorId}/bookings/stats`;
      let bookingsOverview = await axios.get(fetchUrl);
      return bookingsOverview.data;
    } catch (error) {
      console.log(error);
    }
  }
  static async getVendorBookings(vendorId, period, page) {
    try {
      const fetchUrl = `${appConfig.MANAGE_API_BASE_URL}/v1/account/${vendorId}/bookings/list?period=${period}&page=${page}`;
      let bookings = await axios.get(fetchUrl);
      return bookings.data;
    } catch (error) {
      console.log(error);
    }
  }
  static async getVendorBookingsCount(vendorId, period, page) {
    try {
      const fetchUrl = `${appConfig.MANAGE_API_BASE_URL}/v1/account/${vendorId}/bookings/count?period=${period}&page=${page}`;
      let bookingsCount = await axios.get(fetchUrl);
      return bookingsCount.data;
    } catch (error) {
      console.log(error);
    }
  }
  static async getBookingById(bookingId) {
    try {
      const fetchUrl = `${appConfig.MANAGE_API_BASE_URL}/v1/booking/details/${bookingId}`;
      let bookings = await axios.get(fetchUrl);
      return bookings.data;
    } catch (error) {
      console.log(error);
    }
  }
  static async getVendorBookingsByStatus(vendorId, status) {
    try {
      const fetchUrl = `${appConfig.MANAGE_API_BASE_URL}/v1/account/${vendorId}/bookings/list-by-status?status=${status}`;
      let bookings = await axios.get(fetchUrl);
      return bookings.data.bookings;
    } catch (error) {
      console.log(error);
    }
  }
}

export default VendorService;
