<template>
  <div class="settings-page">
    <h3 class="header-bar">
      <img src="@/assets/icons/manage-bookings.svg" alt="" class="header-bar-title-icon" />
      Bussiness Profile
    </h3>
    <div class="settings-card">
      <div class="settings-dp"></div>

      <div class="settings-item">
        <label for="" class="settings__input-label">Bussines name</label>
        <div class="settings__input-wrap">
          <input type="text" class="settings__input" placeholder="Enter Bussiness name" />
        </div>
      </div>
      <div class="settings-item">
        <label for="" class="settings__input-label">Phone no.</label>
        <div class="settings__input-wrap">
          <input type="number" class="settings__input" placeholder="Enter Bussiness phone no." />
        </div>
      </div>
      <div class="settings-item">
        <label for="" class="settings__input-label">Bussines name</label>
        <div class="settings__input-wrap">
          <input type="text" class="settings__input" placeholder="Enter Bussiness name" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';

.settings-page {
  margin-top: 50px;
  padding: 20px 12px;

  .header-bar {
    display: flex;
    align-items: center;
    padding: 14px 0;
    gap: 8px;
    font-size: 14px;
    font-weight: 400;
  }

  .settings-card {
    background-color: #fff;
    box-shadow: 0 0 1px #00000025;
    padding: 12px;
    border-radius: 4px;

    .settings-item {
      margin-bottom: 14px;
    }
    .settings__input-label {
      font-size: 14px;
      font-weight: 500;
      color: #0d21316a;
    }
    .settings__input-wrap {
      margin-top: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      //   border: 1px solid #00000025;
      background-color: #edf2f759;
      border-radius: 6px;
      overflow: hidden;
      padding: 0 14px;
      .settings__input {
        outline: none;
        width: 100%;
        padding: 12px 0;
        box-sizing: border-box;
        background-color: #edf2f759;
        font-size: 12px;
        border: none;
        &::placeholder {
          color: #00000044;
        }
      }
      .settings__input-icon {
        width: 14px;
      }
    }
  }
}
</style>
