import appConfig from '@/config/index.ts';
// import router from "@/router";
import axios from 'axios';

class VendorService {
  static async updateBookingStatus(bookingId, status) {
    try {
      console.log(bookingId);
      const fetchUrl = `${appConfig.MANAGE_API_BASE_URL}/v1/booking/${bookingId}/update/status`;
      let updatedBooking = await axios.post(fetchUrl, { status });
      return updatedBooking.data;
      //    console.log(vendor, "vendor");
    } catch (error) {
      console.log(error);
    }
  }
}

export default VendorService;
