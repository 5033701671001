export default {
    methods: {
        getTimeAgo(createdAt) {
            const currentTime = new Date();
            const createdTime = new Date(createdAt);
            const timeDifference = currentTime - createdTime;
            const seconds = Math.floor(timeDifference / 1000);

            if (seconds < 60) {
                return `${seconds} seconds ago`;
            }

            const minutes = Math.floor(timeDifference / (1000 * 60));

            if (minutes < 60) {
                return `${minutes} minutes ago`;
            }

            const hours = Math.floor(timeDifference / (1000 * 60 * 60));

            if (hours < 24) {
                return `${hours} hours ago`;
            }

            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

            if (days < 30) {
                return `${days} days ago`;
            }

            const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));

            if (months < 12) {
                return `${months} months ago`;
            }

            const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));
            return `${years} years ago`;
        },
    }
}