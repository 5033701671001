import appConfig from '@/config/index.ts';
// import router from "@/router";
import axios from 'axios';

class VendorService {
  static async updateUser(userId, data) {
    try {
      const fetchUrl = `${appConfig.MANAGE_API_BASE_URL}/v1/vendor-user/${userId}/update/details`;
      let updatedUser = await axios.post(fetchUrl, data);
      return updatedUser.data;
    } catch (error) {
      console.log(error);
    }
  }
}

export default VendorService;
