import { Module } from 'vuex';

interface Vendor {
  id: number;
  vendor_name: string;
  vendor_slug: string;
}

interface VendorState {
  vendor: Vendor | null;
  userVendors: Vendor[] | null; // Use Vendor[] instead of [Vendor]
}

const userModule: Module<VendorState, any> = {
  namespaced: true,
  state: {
    vendor: null,
    userVendors: null,
  },
  mutations: {
    setVendor(state, vendor) {
      state.vendor = vendor;
    },
    setUserVendors(state, vendors) {
      state.userVendors = vendors;
    },
  },
  actions: {
    setVendor({ commit }, vendor) {
      commit('setVendor', vendor); // Corrected the mutation name
    },
    setUserVendors({ commit }, vendors) {
      commit('setUserVendors', vendors); // Corrected the mutation name
    },
  },
  getters: {
    getVendor(state) {
      return state.vendor;
    },
    getUserVendors(state) {
      return state.userVendors;
    },
  },
};

export default userModule;
