<template>
  <div class="navbar">
    <div class="navbar-inner">
      <div class="navbar-left">
        <div class="navbar__menu" @click="showSidbar = !showSidbar">
          <img src="@/assets/icons/menu.svg" alt="" class="navbar__menu-icon" />
        </div>

        <div class="navbar__vendor" v-if="vendor && vendor.vendor_name" @click.stop="navigateTo('VendorDashboardView')">
          <div class="vendor__info">
            <h4 class="vendor__info-name">{{ vendor.vendor_name }}</h4>
            <p class="vendor__info-address">
              <span class="vendor__info-address">{{ vendor.address.address_line_2 + ', ' + vendor.address.city }}</span>
              <!-- <img src="@/assets/icons/arrow-down.svg" alt="" class="vendor__info-address-arrow" /> -->
            </p>
          </div>
        </div>
      </div>

      <div class="navbar__brand">
        <img src="@/assets/logo-sm.svg" alt="" class="navbar__brand-logo" />
      </div>
    </div>
  </div>

  <div class="sidebar" v-if="showSidbar" @click="showSidbar = false">
    <div class="sidebar-inner" :class="showSidbar ? '--open' : '--close'">
      <div class="sidebar-item" @click.stop="navigateTo('BookingRequests')">
        <div class="sidebar-item-icon-wrap">
          <img src="@/assets/icons/dash-icon0.svg" alt="" class="sidebar-item-icon" />
        </div>
        <p class="options__row-item-label">Booking Request</p>
      </div>
      <div class="sidebar-item" @click.stop="navigateTo('ManageBookings')">
        <div class="sidebar-item-icon-wrap">
          <img src="@/assets/icons/dash-icon1.svg" alt="" class="sidebar-item-icon" />
        </div>
        <p class="options__row-item-label">Manage Bookings</p>
      </div>
      <div class="sidebar-item" @click.stop="navigateTo('ConcludeBookings')">
        <div class="sidebar-item-icon-wrap">
          <img src="@/assets/icons/dash-icon3.svg" alt="" class="sidebar-item-icon" />
        </div>
        <p class="options__row-item-label">Conclude Bookings</p>
      </div>
      <div class="sidebar-item">
        <div class="sidebar-item-icon-wrap">
          <img src="@/assets/icons/dash-icon2.svg" alt="" class="sidebar-item-icon" />
        </div>
        <p class="options__row-item-label">Upcoming Bookings</p>
      </div>
      <div class="sidebar-item" @click.stop="navigateTo('BookingHistoryPage')">
        <div class="sidebar-item-icon-wrap">
          <img src="@/assets/icons/dash-icon4.svg" alt="" class="sidebar-item-icon" />
        </div>
        <p class="options__row-item-label">Booking History</p>
      </div>
      <div class="sidebar-item">
        <div class="sidebar-item-icon-wrap">
          <img src="@/assets/icons/dash-icon5.svg" alt="" class="sidebar-item-icon" />
        </div>
        <p class="options__row-item-label">Support</p>
      </div>
      <div class="sidebar-item" @click.stop="navigateTo('AccountsListView')">
        <div class="sidebar-item-icon-wrap">
          <i class="light-icon-apps sidebar-item-icon"> </i>
        </div>
        <p class="options__row-item-label">Accounts</p>
      </div>
      <div class="sidebar-item" @click.stop="navigateTo('UserSettings')">
        <div class="sidebar-item-icon-wrap">
          <i class="light-icon-user sidebar-item-icon"> </i>
        </div>
        <p class="options__row-item-label">User Settings</p>
      </div>
    </div>
  </div>
</template>

<script>
// import LvDrawer from 'lightvue/drawer';
export default {
  props: ['vendor'],
  components: {},
  data() {
    return {
      showSidbar: false,
    };
  },
  methods: {
    navigateTo(name) {
      this.showSidbar = false;
      this.$router.push({
        name: name,
        params: { vendorId: this.vendor?._id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;

  .navbar-inner {
    max-width: 500px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;

    background-color: #fff;
    box-shadow: 0 0 1px #00000025;

    .navbar-left {
      display: flex;
      align-items: center;
      gap: 12px;

      .navbar__menu {
        background-color: #fcfcfc;
        height: 34px;
        width: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        cursor: pointer;
      }

      .navbar__vendor {
        cursor: pointer;
        .vendor__info {
          .vendor__info-name {
            font-size: 16px;
            font-weight: 400;
          }
          .vendor__info-address {
            font-size: 12px;
            font-weight: 300;
            display: flex;
            align-items: center;
            gap: 6px;

            .vendor__info-address-arrow {
              width: 14px;
            }
          }
        }
      }
    }
  }
}

@keyframes openSidebar {
  from {
    left: -250px;
  }
  to {
    left: 0;
  }
}
@keyframes closeSidebar {
  from {
    left: 0;
  }
  to {
    left: -250px;
  }
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 99;
  background-color: #00000025;
  .sidebar-inner {
    // padding: 12px;
    background-color: #fff;
    box-shadow: 0 0 1px #00000025;
    width: 250px;
    height: 100vh;
    padding: 32px 0;
    box-sizing: border-box;
    position: relative;

    &.--open {
      animation: openSidebar 0.25s forwards;
    }
    &.--close {
      animation: closeSidebar 0.25s forwards;
    }

    .sidebar-item {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      padding: 12px;
      border-radius: 4px;

      &:hover {
        background-color: #f0f0f0;
      }

      .sidebar-item-icon-wrap {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #00808009;
        border-radius: 40px;
        .options__row-item-icon {
          width: 20px;
        }
      }
      .sidebar-item-label {
        font-size: 9px;
      }
    }
  }
}
</style>
