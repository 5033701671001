<template>
  <div class="mb__bookings-item">
    <div class="mb__bookings-item-header">
      <h4 class="item-header-name">
        {{ booking.customer.name }}
        <img src="@/assets/icons/verified-tick.svg" alt="" class="item-header-icon" />
      </h4>
      <h4 class="item-header-booking-timeago" v-if="forPage == 'open'">
        {{ getTimeAgo(booking.createdAt) }}
      </h4>
      <h4 class="item-header-booking-id" v-else>{{ booking.booking_id }}</h4>
    </div>
    <div class="mb__bookings-item-footer">
      <div class="mb__bookings-item-left">
        <div class="item-left-details">
          <div class="item-left-details-tickets">
            <h4 class="ticket-value">{{ booking.no_of_people }}</h4>
            <h6 class="ticket-unit">Tickets</h6>
          </div>
          <div class="item-left-details-info">
            <p class="item-left-activity" v-if="booking.activity">
              {{ booking.activity.activity_name + ' ' + booking.activity.activity_variant }}
            </p>
            <div class="item-left-slot">
              <div class="item-left-slot-item"><i class="light-icon-calendar"></i>{{ converDate(booking.activity_date) }}</div>
              <div class="item-left-slot-item"><i class="light-icon-clock"></i>{{ booking.activity_slot }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb__bookings-item-right">
        <div class="item-right-cta-box" v-if="forPage == 'open'">
          <div class="item-right-cta --filled" @click.stop="updateStatus('accepted')">
            <i class="light-icon-check"></i>
          </div>
          <div class="item-right-cta --hollow" @click.stop="updateStatus('rejected')">
            <i class="light-icon-x"></i>
          </div>
        </div>
        <div class="item-right-cta-box" v-else-if="['awaiting', 'checkin', 'inreview', 'concluded'].includes(forPage)">
          <div class="item-right-cta --long --hollow" v-if="['awaiting', 'checkin'].includes(forPage)">Update</div>
          <div class="item-right-cta --long --hollow" v-if="['inreview'].includes(forPage)" @click.stop="showComments = !showComments">
            Comments
            <i :class="!showComments ? 'light-icon-chevron-down' : 'light-icon-chevron-up'"></i>
          </div>
          <div class="item-right-cta --long --hollow" v-if="['concluded'].includes(forPage)">View</div>
        </div>
        <div class="item-right-cta --hollow" v-else>
          <i class="light-icon-arrow-narrow-right"></i>
        </div>
      </div>
    </div>
    <div class="mb__bookings-item-dropdown" v-show="showComments">
      <div class="mb__bookings-item-dropdown-comment"><strong>Comment:</strong> ksjskds djdkjjd kjsd ksjsk sksjksjs skjks sj</div>
    </div>
  </div>
</template>

<script>
import ActivityService from '@/services/activities/ActivityService';
import BookingUpdateService from '@/services/bookings/BookingUpdateService';

import convertDateTime from '@/mixins/common/convertDateTime';
export default {
  mixins: [convertDateTime],
  props: ['booking', 'forPage'],
  data() {
    return {
      activity: '',
      showComments: false,
    };
  },
  mounted() {
    // this.getActiviy(this.booking.activity_slug);
  },
  methods: {
    async updateStatus(status) {
      console.log('started');
      let updatedBooking = await BookingUpdateService.updateBookingStatus(this.booking._id, status);
      console.log(updatedBooking, 'updated');
    },
    // async getActiviy(slug) {
    //   this.activity = await ActivityService.getActivityBySlug(slug);
    //   console.log(this.activity);
    // },
    converDate(d) {
      let date = new Date(d);
      const options = {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      };

      const formattedDate = date.toLocaleDateString('en-US', options);
      return formattedDate;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';
.mb__bookings-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  box-shadow: 0 0 1px #00000025;
  background-color: #fff;
  border-radius: 4px;
  margin: 6px 0;

  .mb__bookings-item-header {
    border-bottom: 1px dashed #00000025;
    padding-bottom: 6px;
    margin-bottom: 6px;
    display: flex;
    align-content: center;
    justify-content: space-between;
    width: 100%;

    .item-header-name,
    .item-header-booking-id {
      font-size: 14px;
      font-weight: 400;
    }
    .item-header-booking-timeago {
      font-size: 12px;
      font-weight: 200;
    }
  }

  .mb__bookings-item-footer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
  }
  .mb__bookings-item-left {
    display: flex;
    flex-direction: column;
    gap: 6px;

    .item-left-details {
      display: flex;
      align-items: center;
      .item-left-details-tickets {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 6px;
        margin-right: 6px;
        border-right: 1px dashed #00000025;
        .ticket-value {
          font-size: 12px;
          text-align: center;
          width: 100%;
        }
        .ticket-unit {
          font-size: 9px;
          text-align: center;
          width: 100%;
        }
      }
      .item-left-details-info {
        .item-left-activity {
          font-size: 12px;
        }
        .item-left-slot {
          font-size: 9px;
          display: flex;
          align-items: center;
          gap: 3px;
          .item-left-slot-item {
            background-color: #fbfbfb;
            padding: 3px;
            border-radius: 3px;
            display: flex;
            align-items: center;
            gap: 2px;
          }
        }
      }
    }
  }

  .mb__bookings-item-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    gap: 4px;

    .item-right-booking-id {
      padding: 0;
      font-size: 12px;
    }
    .item-right-cta-box {
      display: flex;
      align-items: center;
      gap: 3px;
    }
    .item-right-cta {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px 4px;
      border-radius: 4px;
      font-size: 12px;
      width: 16px;
      height: 16px;

      &:hover {
        transform: scale(1.05);
      }
      &.--long {
        width: fit-content;
        font-size: 9px;
      }
      &.--filled {
        background-color: $theme-color;
        color: #fff;
      }
      &.--hollow {
        background-color: #f5f5f5;
      }
    }
  }
}
.mb__bookings-item-dropdown {
  padding: 8px 0 0;
  width: 100%;
  box-sizing: border-box;

  .mb__bookings-item-dropdown-comment {
    font-size: 12px;
    font-weight: 200;
  }
}
</style>
