<template>
  <div>
    <!-- <navbar-section :vendor="vendor"></navbar-section> -->
    <!-- <page-section> -->
    <div class="shimmers" v-if="!booking">
      <list-shimmer :height="'200px'"></list-shimmer>
      <list-shimmer :height="'100px'"></list-shimmer>
      <list-shimmer :height="'50px'"></list-shimmer>
      <list-shimmer :height="'50px'"></list-shimmer>
    </div>
    <div class="customer-details" v-if="booking">
      <div class="details-card">
        <div class="card__customer-header">
          <h3 class="customer-name">{{ booking.customer.name }}</h3>
          <img src="@/assets/icons/verified-tick.svg" alt="" class="customer-verfied" />
        </div>
        <div class="card__customer-contact">
          <img src="@/assets/icons/call.svg" alt="" class="customer-mob-icon" />
          <p class="customer-mob">{{ booking.customer.phone_number }}</p>
        </div>
        <div class="card__bookingid">
          <h4 class="card__bookingid-label">Booking Id:</h4>
          <h4 class="card__bookingid-value">{{ booking.booking_id }}</h4>
        </div>

        <div class="card__activity-details">
          <div class="card__activity-details-tickets">
            <h4 class="ticket-value">{{ booking.no_of_people }}</h4>
            <h6 class="ticket-unit">Tickets</h6>
          </div>
          <div class="card__activity-details-info">
            <p class="card__activity-activity">
              {{ booking.activity.activity_name + ' ' + booking.activity.activity_variant }}
            </p>
            <p class="card__activity-slot">
              {{ converDate(booking.activity_date) }} -
              {{ booking.activity_slot }}
            </p>
          </div>
        </div>
      </div>

      <div class="payment-card">
        <div class="payment-card__header">
          <h4 class="payment-card__header-title">PAYMENT DETAILS</h4>
          <h5 class="payment-card__header-status">Partially paid</h5>
        </div>
        <div class="payment-card__details">
          <div class="payment-card__details-label">Unit Price:</div>
          <MoneyFormat :amount="booking.total_price / booking.no_of_people" />
        </div>
        <div class="payment-card__details">
          <div class="payment-card__details-label">No. of person</div>
          <div>x{{ booking.no_of_people }}</div>
        </div>
        <div class="payment-card__details --highlighted">
          <div class="payment-card__details-label">Total Amount:</div>
          <MoneyFormat :amount="booking.total_price" />
        </div>
      </div>

      <div class="accept-card" v-if="detailsType == 'check-in'">
        <div class="accept-card__comment">
          <label class="accept-card__comment-label" for="comments">Add Comments</label>
          <textarea class="accept-card__comment-input" name="" id="comments" cols="30" rows="3"></textarea>
        </div>
        <div class="accept-card__ctas">
          <div class="accept-card__ctas-back">
            <i class="light-icon-arrow-left"></i>
          </div>
          <div class="accept-card__ctas-btn">
            <i class="accept-card__ctas-btn-icon light-icon-check"></i>
            <p class="accept-card__btn-text">Check in</p>
          </div>
        </div>
      </div>

      <!-- Guest Remarks Card -->
      <div class="accept-card" v-if="false">
        <div class="accept-card__collapsible-header" @click="openGuestComments = !openGuestComments">
          Guest Remarks
          <i :class="!openGuestComments ? 'light-icon-chevron-down' : 'light-icon-chevron-up'"></i>
        </div>
        <LvCollapsible :show="openGuestComments">
          <div class="collapisible--comment">
            <p>LightVue is the most comprehensive, performant and easy to use set of UI components for Vue.js. It provides a wide range of high quality Widgets and Plugins built with accessibility, responsiveness and performance in mind.</p>
          </div>
        </LvCollapsible>
      </div>

      <!-- Vendor Comments Card -->
      <div class="accept-card" v-if="false">
        <div class="accept-card__collapsible-header" @click="openVendorComments = !openVendorComments">
          Comments
          <i :class="!openVendorComments ? 'light-icon-chevron-down' : 'light-icon-chevron-up'"></i>
        </div>
        <LvCollapsible :show="openVendorComments">
          <div class="collapisible--comment">
            <p>LightVue is the most comprehensive, performant and easy to use set of UI components for Vue.js. It provides a wide range of high quality Widgets and Plugins built with accessibility, responsiveness and performance in mind.</p>
          </div>
        </LvCollapsible>
      </div>

      <div class="checkin-btn" @click="checkIn('checked-in')" v-if="detailsType == 'check-in'">
        <p class="checkin-btn-text">{{ checkedIn == 'checked-in' ? 'CHECKED IN' : 'CONFIRM CHECK-IN' }}</p>
      </div>
    </div>
  </div>
  <!-- </page-section> -->
</template>

<script>
// import PageSection from '@/components/common/PageSection.vue';
import VendorBookingService from '@/services/bookings/VendorBookingsService';
import LvCollapsible from 'lightvue/collapsible';
import ListShimmer from '@/components/common/ListShimmer.vue';
import BookingUpdateService from '@/services/bookings/BookingUpdateService';
import MoneyFormat from '@/components/common/MoneyFormat.vue';
export default {
  components: { LvCollapsible, ListShimmer, MoneyFormat },
  data() {
    return {
      booking: null,
      activity: null,
      vendor: null,
      openGuestComments: false,
      openVendorComments: false,
      checkedIn: false,
    };
  },
  computed: {
    bookingId() {
      return this.$route.params.bookingId;
    },
    detailsType() {
      return this.$route.query.detailsType;
    },
  },
  mounted() {
    this.getBooking();
  },
  methods: {
    async getBooking() {
      let data = await VendorBookingService.getBookingById(this.bookingId);
      this.booking = data.Booking;
      if (this.booking.booking_staus == 'checked-in') {
        this.checkedIn = true;
      }
      console.log(data, 'booking');
    },
    converDate(d) {
      let date = new Date(d);
      const options = {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      };

      const formattedDate = date.toLocaleDateString('en-US', options);
      return formattedDate;
    },
    async checkIn(status) {
      console.log('started');
      if (this.booking) {
        console.log(this.booking);
        let updatedBooking = await BookingUpdateService.updateBookingStatus(this.booking._id, status);
        console.log(updatedBooking, 'updated');
        this.checkedIn = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

.shimmers {
  margin-top: 50px;
  padding: 20px 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.checkin-btn {
  margin: 16px auto 0;
  width: fit-content;
  color: #ffffff;
  background-color: $theme-color;
  padding: 8px 24px;
  font-size: 14px;
  text-align: center;
  border-radius: 50px;
  cursor: pointer;

  &:hover {
    background-color: $theme-color-hover;
  }
}

.customer-details {
  margin-top: 50px;
  padding: 20px 12px;

  .details-card {
    background-color: #fff;
    border-radius: 10px;
    padding: 16px;
    box-shadow: 0 0 1px #00000025;

    .card__customer-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .card__customer-contact {
      display: flex;
      align-items: center;
      gap: 6px;
      margin-top: 8px;

      .customer-mob-icon {
        width: 14px;
      }
      .customer-mob {
        font-size: 14px;
      }
    }
    .card__bookingid {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      border-radius: 5px;
      background-color: #fbfbfb;
      margin: 8px 0;

      .card__bookingid-label,
      .card__bookingid-value {
        font-weight: 400;
      }
    }

    .card__activity-details {
      display: flex;
      align-items: center;
      margin-top: 12px;
      .card__activity-details-tickets {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 6px;
        margin-right: 3px;
        border-right: 1px solid #00000025;
        .ticket-value {
          font-size: 14px;
          text-align: center;
          width: 100%;
        }
        .ticket-unit {
          font-size: 9px;
          text-align: center;
          width: 100%;
        }
      }
      .card__activity-details-info {
        margin-left: 12px;
        .card__activity-activity {
          font-size: 14px;
        }
        .card__activity-slot {
          font-size: 12px;
        }
      }
    }
  }

  .payment-card {
    background-color: #fff;
    border-radius: 10px;
    padding: 16px;
    box-shadow: 0 0 1px #00000025;
    margin-top: 14px;

    .payment-card__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #00000025;
      padding-bottom: 12px;
      margin-bottom: 12px;

      .payment-card__header-title {
        font-size: 14px;
        font-weight: 400;
      }
      .payment-card__header-status {
        font-size: 12px;
        font-weight: 300;
        color: #db5d33cc;
      }
    }

    .payment-card__details {
      display: flex;
      align-items: center;
      justify-content: space-between;

      opacity: 0.7;
      font-size: 12px;
      &.--highlighted {
        font-weight: 600;
        opacity: 1;
        font-size: 14px;
      }
    }
  }

  .accept-card {
    background-color: #fff;
    border-radius: 6px;
    padding: 12px;
    box-shadow: 0 0 1px #00000025;
    margin-top: 12px;
    .accept-card__comment {
      .accept-card__comment-label {
        font-size: 14px;
      }
      .accept-card__comment-input {
        margin-top: 12px;
        border: none;
        background-color: #edf2f772;
        width: 100%;
        padding: 12px;
        box-sizing: border-box;
        border-radius: 6px;
        outline: none;
      }
    }
    .accept-card__ctas {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 14px;

      .accept-card__ctas-back {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        background-color: #edf2f772;
        border-radius: 4px;
        width: 18px;
        height: 18px;
      }

      .accept-card__ctas-btn {
        background-color: $theme-color;
        padding: 8px 14px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 4px;
        color: #fff;
        border-radius: 6px;
        font-size: 14px;
      }
    }

    .accept-card__collapsible-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .collapisible--comment {
      margin-top: 14px;
      font-size: 12px;
      padding-top: 12px;
      border-top: 1px dashed #0000000e;
    }
  }
}
</style>
