<template>
  <div class="login-page">
    <div class="page__inner">
      <h2 class="page__header">Sign up</h2>

      <div class="page__auth-providers">
        <div class="auth-providers-btn">Facebook</div>
        <div class="auth-providers-btn" @click="signupWithGoogle()">Google</div>
      </div>

      <div class="page__or-bar">
        <div class="or-bar__hr-line"></div>
        <h5>or</h5>
        <div class="or-bar__hr-line"></div>
      </div>

      <div class="page__auth-form">
        <input
          v-model="firstName"
          type="text"
          placeholder="First Name:"
          class="auth-form__input"
        />
        <input
          v-model="lastName"
          type="text"
          placeholder="Last Name:"
          class="auth-form__input"
        />
        <input
          v-model="email"
          type="email"
          placeholder="Email:"
          class="auth-form__input"
        />
        <input
          v-model="password"
          type="password"
          placeholder="Password:"
          class="auth-form__input"
        />
        <p class="auth-form__input-other-options">Forget Password?</p>
      </div>

      <div class="cta-container">
        <div class="cta-btn" @click="register()">Sign Up</div>
      </div>

      <div class="page__other-auth-option">
        <p @click="$router.push({ name: 'LoginPage' })">
          Don't have an account?
          <strong class="page__other-auth-option-link">Sign-In</strong>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/user/AuthService";
import userMixin from "@/mixins/user/userMixin";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
export default {
  mixins: [userMixin],
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    };
  },
  mounted() {
    console.log(this.user);
  },
  methods: {
    register() {
      if (this.password) {
        console.log("register user");

        const auth = getAuth();
        createUserWithEmailAndPassword(auth, this.email, this.password)
          .then(() => {
            // Signed in
            AuthService.login().then(() => {
              console.log(this.user, "User Registered");
            });
          })
          .catch((error) => {
            // put alert
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage, "Error");
          });
      } else {
        console.log("error");
      }
    },
    signupWithGoogle() {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then(() => {
          // signed in
          AuthService.login().then(() => {
            console.log(this.user, "User Registered");
          });
        })
        .catch((error) => {
          // put alert
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables";

.login-page {
  .page__inner {
    min-height: 100vh;
    padding: 32px 16px;
    box-sizing: border-box;

    .page__header {
      font-size: 24px;
    }

    .page__auth-providers {
      margin: 24px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;

      .auth-providers-btn {
        width: calc(50% - 10px);
        text-align: center;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 30px;
        background-color: #eeeeee;
      }
    }

    .page__or-bar {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin-bottom: 24px;

      .or-bar__hr-line {
        border-bottom: 1px solid #cccccc;
        width: 42%;
      }
    }

    .page__auth-form {
      margin: 32px 0;
      .auth-form__input {
        width: 100%;
        outline: none;
        border: 1px solid #cccccc;
        border-radius: 40px;
        padding: 12px 16px;
        font-size: 16px;
        box-sizing: border-box;
        margin-bottom: 16px;
      }
      .auth-form__input-other-options {
        text-align: right;
        font-size: 14px;
        color: #aaa;
      }
    }

    .cta-container {
      width: 100%;
      margin-top: 24px;
      .cta-btn {
        width: calc(100% - 32px);
        margin: 16px auto;
        background-color: $theme-color;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 40px;
        color: #fff;

        &:hover {
          transform: scale(1.02);
        }

        &.--hollow {
          background-color: transparent;
          border: 2px solid $theme-color;
          color: $theme-color;
        }
      }
    }
    .page__other-auth-option {
      position: absolute;
      bottom: 32px;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .page__other-auth-option-link {
        color: $theme-color;

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}
@media (min-width: 600px) {
  .login-page {
    .page__inner {
      width: 500px;
      margin: 80px auto;
    }
  }
}
</style>
