<template>
  <div class="money-format">&#8377;{{ parseInt(amount).toLocaleString() }}/-</div>
</template>

<script>
export default {
  props: {
    amount: {
      type: Number,
    },
  },
};
</script>
