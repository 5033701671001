<template>
  <div class="login-page">
    <img src="/images/rishikesh-onboarding-bg.jpg" alt="" class="login-bg-img" />
    <div class="page__inner">
      <!-- <h2 class="page__header">Log In</h2> -->

      <!-- <div class="page__auth-providers">
        <div class="auth-providers-btn">Facebook</div>
        <div class="auth-providers-btn" @click="loginWithGoogle()">Google</div>
      </div> -->

      <!-- <div class="page__or-bar">
        <div class="or-bar__hr-line"></div>
        <h5>or</h5>
        <div class="or-bar__hr-line"></div>
      </div> -->

      <img src="@/assets/images/logo/login-logo.svg" alt="" class="login-page-logo" />

      <div class="page__auth-form">
        <input v-model="email" type="email" placeholder="Email:" class="auth-form__input" />
        <input v-model="password" type="password" placeholder="Password:" class="auth-form__input" />
        <p class="auth-form__input-other-options">Forget Password?</p>
      </div>

      <div class="cta-container">
        <div class="cta-btn" v-if="!loading" @click="login()">Log In</div>
        <div class="cta-btn" v-else><lv-loader type="ball-beat" color="#ffffff" /></div>
      </div>

      <!-- <div class="page__other-auth-option">
        <p @click="$router.push({ name: 'SignupPage' })">
          Don't have an account?
          <strong class="page__other-auth-option-link">Sign-up</strong>
        </p>
      </div> -->
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/user/AuthService';
import userMixin from '@/mixins/user/userMixin';
import LvLoader from 'lightvue/loaders';
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
  mixins: [userMixin],
  components: { LvLoader },
  data() {
    return {
      loading: false,
      email: '',
      password: '',
      username: '',
      displayName: '',
    };
  },
  mounted() {
    console.log(this.user);
  },
  methods: {
    login() {
      this.loading = true;
      if (this.password == this.confirmPassword) {
        console.log('register user');
      }
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((/*user*/) => {
          // Signed in
          AuthService.login();
          // this.$router.push({ name: "MyVendors" });
        })
        .catch(error => {
          // put alert
          const errorCode = error.code;
          const errorMessage = error.message;
          toast(`Login failed: ${error.message}`, {
            autoClose: 1000,
            type: 'error',
          });
          this.loading = false;
          console.log(errorCode, errorMessage, 'Error');
        });
      // this.loading = false;
    },
    loginWithGoogle() {
      this.loading = true;
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then((/*user*/) => {
          AuthService.login();
        })
        .catch(error => {
          // put alert
          console.log(error);
        });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  height: 100vh;
  .login-bg-img {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    object-fit: cover;
    z-index: -1;
  }
  .page__inner {
    // min-height: 100vh;
    padding: 32px 16px 16px;
    box-sizing: border-box;
    background-image: linear-gradient(#ffffff50, #ffffff8e);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(4px);

    .page__header {
      font-size: 24px;
    }

    .login-page-logo {
      width: 158px;
      margin: 0 auto;
    }
    .page__auth-providers {
      margin: 24px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;

      .auth-providers-btn {
        width: calc(50% - 10px);
        text-align: center;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 30px;
        background-color: #eeeeee;
        cursor: pointer;
      }
    }

    .page__or-bar {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin-bottom: 24px;

      .or-bar__hr-line {
        border-bottom: 1px solid #cccccc;
        width: 42%;
      }
    }

    .page__auth-form {
      margin: 48px 0 16px;
      .auth-form__input {
        width: 100%;
        outline: none;
        border: none;
        // border: 1px solid #cccccc;
        border-radius: 4px;
        padding: 12px 16px;
        font-size: 16px;
        box-sizing: border-box;
        margin-bottom: 16px;

        font-size: 14px;
      }
      .auth-form__input-other-options {
        text-align: right;
        font-size: 12px;
        color: #373737;
        cursor: pointer;
      }
    }

    .cta-container {
      width: 100%;
      .cta-btn {
        width: calc(100%);
        margin: 0 auto;
        background-color: $theme-color;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;

        &:hover {
          background-color: $theme-color-hover;
        }

        &.--hollow {
          background-color: transparent;
          border: 2px solid $theme-color;
          color: $theme-color;
        }
      }
    }
    .page__other-auth-option {
      width: 100%;
      display: flex;
      align-items: center;

      .page__other-auth-option-link {
        color: $theme-color;

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}
@media (min-width: 600px) {
  .login-page {
    .page__inner {
      width: 500px;
      margin: 80px auto;
    }
  }
}
</style>
