<template>
  <div>
    <div class="accounts-page">
      <div class="accounts-user-nav">
        <div class="navbar-left">
          <div class="navbar__menu" @click="showSidbar = !showSidbar">
            <img src="@/assets/icons/menu.svg" alt="" class="navbar__menu-icon" />
          </div>
          <div class="brand-name" @click="$router.push('/')">RISHIKESH.APP</div>
        </div>
        <div class="navbar__brand">
          <img src="@/assets/logo-sm.svg" alt="" class="navbar__brand-logo" />
        </div>
      </div>
      <h3 class="accounts-greetings">Welcome 🤟🏻</h3>
      <!-- <div @click="$router.push({ name: 'VendorDashboardView', params: { vendorId: '6458898f8ff83e5eadc194e1' } })">Himalayan Bungy</div> -->

      <div class="my-accounts">
        <!-- <h4 class="my-accounts-label">My accounts</h4> -->

        <div class="accounts-list">
          <shimmer v-for="i in 2" :key="i" v-show="!userVendors || !userVendors.length" :height="'88px'" class="accounts-list__item"></shimmer>
          <div v-show="userVendors && userVendors.length" class="accounts-list__item" v-for="vendor in userVendors" :key="vendor._id" @click="$router.push({ name: 'VendorDashboardView', params: { vendorId: vendor._id } })">
            <img :src="vendor.vendor_logo" class="item-logo" />
            <div class="item-info">
              <h5 class="item-name">{{ vendor.vendor_name }}</h5>
              <p class="item-location">{{ vendor.address.address_line_2 + ', ' + vendor.address.city }}</p>
            </div>
          </div>

          <div class="accounts-list__item" v-if="!loading && userVendors?.length === 0" @click="showAddVendor = true">
            <div class="item-logo">
              <i class="light-icon-plus"></i>
            </div>
            <div class="item-info">
              <h5 class="item-name">ADD NEW ACCOUNT</h5>
              <p class="item-location"></p>
            </div>
          </div>
          <div class="add-modal" @click="showAddVendor = false" v-if="showAddVendor">
            <div class="add-modal-card">
              <h4 class="add-vendor-note">New onboarding is temporarily closed !! For more details, please contact: +91-9817225332</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sidebar" v-if="showSidbar" @click="showSidbar = false">
      <div class="sidebar-inner" :class="showSidbar ? '--open' : '--close'">
        <div class="sidebar-item" @click.stop="navigateTo('AccountsListView')">
          <div class="sidebar-item-icon-wrap">
            <i class="light-icon-home sidebar-item-icon"> </i>
          </div>
          <p class="options__row-item-label">Home</p>
        </div>
        <div class="sidebar-item" @click.stop="navigateTo('UserSettings')">
          <div class="sidebar-item-icon-wrap">
            <i class="light-icon-settings sidebar-item-icon"> </i>
          </div>
          <p class="options__row-item-label">User Settings</p>
        </div>
        <div class="sidebar-item" @click.stop="logout()" style="position: fixed; bottom: 0px; width: 250px">
          <div class="sidebar-item-icon-wrap">
            <i class="light-icon-logout sidebar-item-icon"> </i>
          </div>
          <p class="options__row-item-label --logout">Log out</p>
        </div>
        <!-- <div class="sidebar-item" @click.stop="logoutAll()">
          <div class="sidebar-item-icon-wrap">
            <i class="light-icon-logout sidebar-item-icon"> </i>
          </div>
          <p class="options__row-item-label --logout">Log out all devices</p>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import vendorMixin from '@/mixins/vendorMixin';
import VendorService from '@/services/vendor/VendorService';
import userMixin from '@/mixins/user/userMixin';
import Shimmer from '@/components/common/ListShimmer.vue';
import { getAuth, signOut } from 'firebase/auth';
import AuthService from '@/services/user/AuthService';

export default {
  mixins: [vendorMixin, userMixin],
  components: { Shimmer },
  mounted() {
    if (this.user && this.user._id) {
      this.getUserVendors();
    }
  },
  data() {
    return {
      loading: false,
      showAddVendor: false,
      showSidbar: false,
    };
  },
  methods: {
    navigateTo(name) {
      this.showSidbar = false;
      this.$router.push({
        name: name,
        params: { vendorId: this.vendor?._id },
      });
    },
    async getUserVendors() {
      this.loading = true;
      let vendors = await VendorService.getUserVendor(this.user._id);
      this.userVendors = [];
      this.userVendors.push(...vendors);
      this.loading = false;
      console.log(vendors, this.userVendors, 'user vendors');
    },
    logout() {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          // Sign-out successful.
          localStorage.removeItem('prebookUser');
          localStorage.removeItem('prebookVendor');
          this.user = null;
          this.$router.push({ name: 'LoginView' });
        })
        .catch(error => {
          // An error happened.
        });
    },
    async logoutAll() {
      await AuthService.logoutAll();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';

.accounts-page {
  // margin-top: 60px;
  padding: 0 12px;

  .accounts-user-nav {
    height: 50px;
    width: calc(100% + 24px);
    box-sizing: border-box;
    padding: 12px;
    margin: 0 -12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 1px #00000025;
    background-color: #fff;
    position: sticky;
    top: 0;
    left: 0;

    .navbar__menu {
      background-color: #fcfcfc;
      height: 34px;
      width: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      cursor: pointer;
    }
    .user-nav__logout {
      color: #d95757;
      cursor: pointer;
    }
  }

  .accounts-greetings {
    font-size: 18px;
    margin: 14px 0;
  }

  .my-accounts {
    .my-accounts-label {
      font-size: 16px;
      margin-bottom: 14px;
    }

    .accounts-list {
      .accounts-list__item {
        padding: 12px;
        background-color: #fff;
        box-shadow: 0 0 1px #00000025;
        border-radius: 6px;
        display: flex;
        align-items: center;
        gap: 12px;
        cursor: pointer;
        margin-bottom: 12px;

        &:hover {
          // box-shadow: 1px 1px 5px #0000000b;
          background-color: #fcfcfc;
        }

        .item-logo {
          width: 64px;
          height: 64px;
          object-fit: contain;
          border-radius: 4px;
          background-color: #f9f9f9;

          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 32px;
        }
        .item-name {
          font-size: 16px;
        }
        .item-location {
          font-size: 14px;
        }
      }

      .add-modal {
        background-color: #00000025;
        backdrop-filter: blur(1px);
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;
        box-sizing: border-box;
        .add-modal-card {
          background-color: #fff;
          border-radius: 4px;
          padding: 12px;
          .add-vendor-note {
            padding: 32px 16px;
            text-align: center;
          }
        }
      }
    }
  }
}

@keyframes openSidebar {
  from {
    left: -250px;
  }
  to {
    left: 0;
  }
}
@keyframes closeSidebar {
  from {
    left: 0;
  }
  to {
    left: -250px;
  }
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 99;
  background-color: #00000025;
  .sidebar-inner {
    // padding: 12px;
    background-color: #fff;
    box-shadow: 0 0 1px #00000025;
    width: 250px;
    height: 100vh;
    padding: 32px 0;
    box-sizing: border-box;
    position: relative;

    &.--open {
      animation: openSidebar 0.25s forwards;
    }
    &.--close {
      animation: closeSidebar 0.25s forwards;
    }

    .sidebar-item {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      padding: 8px;
      border-radius: 4px;

      &:hover {
        background-color: #f0f0f0;
      }

      .--logout {
        // color: #d95757;
      }
      .sidebar-item-icon-wrap {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #00808009;
        border-radius: 40px;
        .options__row-item-icon {
          width: 20px;
        }
      }
      .sidebar-item-label {
        font-size: 9px;
      }
    }
  }
}
.navbar-left {
  display: flex;
  align-items: center;
  gap: 12px;
  .brand-name {
    // color: #008080;
  }
}
</style>
