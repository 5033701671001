<template>
  <div class="auth-page">
    <div class="page__inner">
      <img
        class="page__illustration-img"
        src="@/assets/images/onboarding/auth-page-illustration.svg"
        alt=""
      />

      <div class="page__text">
        <h2 class="page__text-heading">Find The Best Destination</h2>
        <p class="page__text-sub-heading">Find hotels, villas, apartment, car rental etc.</p>
      </div>

      <div class="page__ctas">
        <div class="cta-container">
          <div class="cta-btn" @click="$router.push({ name: 'LoginPage' })">
            Sign In
          </div>
        </div>
        <div class="cta-container">
          <div
            class="cta-btn --hollow"
            @click="$router.push({ name: 'SignupPage' })"
          >
            Register Now
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables";

.auth-page {
  .page__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 100vh;
    padding: 16px;
    box-sizing: border-box;

    .page__illustration-img {
        margin-bottom: 24px;
    }
    .page__text {
        margin-bottom: 24px;
        text-align: center;

        .page__text-heading {
            font-size: 24px;
            margin-bottom: 6px;
        }
        .page__text-sub-heading {
            color: #808080;
        }
    }
    .page__ctas {
      margin: 30px 0 80px;
      .cta-container {
        width: 100%;
        bottom: 0;
        .cta-btn {
          width: calc(100% - 32px);
          margin: 16px auto;
          background-color: $theme-color;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 40px;
          color: #fff;
          cursor: pointer;

          &:hover {
            transform: scale(1.02);
            opacity: .9;
          }

          &.--hollow {
            background-color: transparent;
            border: 2px solid $theme-color;
            color: $theme-color;
          }
        }
      }
    }
  }
}
@media (min-width: 600px) {
  .auth-page {
    .page__inner {
        width: 500px;
        margin: 0 auto;
    }
  }
}
</style>
