
import { defineComponent, ref } from 'vue';
import { Chart, Line, Area, Tooltip } from 'vue3-charts';

export default defineComponent({
  name: 'LineChart',
  components: { Chart, Line, Area, Tooltip },
  props: ['series'],
  setup() {
    const direction = ref('horizontal');
    const margin = ref({
      left: 0,
      top: 20,
      right: 0,
      bottom: 8,
    });
    const axis = ref({
      primary: {
        type: 'band',
        format: (val: any) => '',
        hide: true,
      },
      secondary: {
        type: 'linear',
        ticks: 4,
        hide: true,
      },
    });

    return { direction, margin, axis };
  },
});
