export default {
  mounted() {
    const locUser = JSON.parse(localStorage.getItem('prebookUser'));
    console.log(locUser);
    if (locUser && locUser._id) {
      this.user = locUser;
    }
  },
  computed: {
    user: {
      get: function () {
        return this.$store.state.user.user;
      },
      set: function (newValue) {
        this.$store.dispatch('user/setUser', newValue);
      },
    },
  },
};
