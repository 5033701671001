// import axios from "axios";
import appConfig from '@/config/index.ts';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import router from '@/router';
import axios from 'axios';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

class AuthService {
  static async login() {
    const auth = getAuth();
    onAuthStateChanged(auth, user => {
      if (user) {
        auth.currentUser.getIdToken(/* forceRefresh */ true).then(idToken => {
          const fetchUrl = `${appConfig.MANAGE_API_BASE_URL}/v1/vendor-user/auth`;
          const options = {
            method: 'OPTIONS',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json;charset=UTF-8',
              Authorization: `Bearer ${idToken}`,
            },
            //   // body: JSON.stringify({ userToken: idToken })
          };

          axios
            .post(fetchUrl, { idToken })
            .then(data => {
              console.log(data.data);
              window.localStorage.setItem('prebookUser', JSON.stringify(data.data));
              // router.push({ name: "DashboardPage", params: {vendorId: "sk"} });
              router.push({ name: 'AccountsListView' });
              return data;
            })
            .catch(error => {
              signOut(auth);
              // put alert
              toast('Login failed', {
                autoClose: 1000,
                type: 'error',
              });
            });
        });
      }
    });
  }

  static async logoutAll() {
    const auth = getAuth();
    onAuthStateChanged(auth, user => {
      if (user) {
        auth.currentUser.getIdToken(/* forceRefresh */ true).then(idToken => {
          const fetchUrl = `${appConfig.MANAGE_API_BASE_URL}/v1/vendor-user/logout-all`;
          const options = {
            method: 'OPTIONS',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json;charset=UTF-8',
              Authorization: `Bearer ${idToken}`,
            },
            //   // body: JSON.stringify({ userToken: idToken })
          };

          axios
            .post(fetchUrl, { idToken })
            .then(data => {
              console.log(data.data);
              window.localStorage.removeItem('prebookUser');
              window.localStorage.removeItem('prebookVendor');
              router.go({ name: 'LoginView' });
              return data;
            })
            .catch(error => {
              // put alert
              console.log(error);
            });
        });
      }
    });
  }
}

export default AuthService;
